import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="w-full">
          <div className="w-2/5 text-xl font-bold inter text-center pt-20 pb-20 ml-50">
            <div className="w-3/4 pl-100 text-left float-right" style={{ lineHeight: "4rem" }}>
              <img src="/shopify-logo.png" className='w-10 inline mr-2'/>
              <span className='pt-2 align-middle'>
                Shopify Web Development
              </span>
            </div>
          </div>
          <div className="w-3/5"></div>
        </div>
        <div className="w-full">
          <div className="flex flex-wrap text-center justify-center">
            <div className="w-2/5 inter text-center pt-20 pb-20">
              <div className="w-3/4 pl-100 text-5xl font-bold text-left float-right" style={{lineHeight: "4rem"}}>
                Official Web Development Partners of Shopify
              </div>
              <div className="w-3/4 pl-100 text-lg font-medium text-left float-right pt-5" style={{ lineHeight: "1.9rem" }}>
                <div className="w-5/6">
                  We have successfully delivered over 5000 projects with 2000+ brands to date. Our teams have extensive expertise in developing custom Shopify and Shopify Plus websites, ensuring a unique and seamless shopping experience for your customers.
                </div>
              </div>
              <div className="w-3/4 pl-100 text-lg font-medium text-left float-right pt-5" style={{ lineHeight: "1.9rem" }}>
                <a href="mailto:info@shopifypartners.dev">
                  <div className="bg-black text-white w-1/2 text-center py-2 px-4 rounded-full mt-5 cursor-pointer">
                    Contact Us
                  </div>
                </a>
              </div>
              <div className="w-3/4 pl-100 text-3xl font-bold text-left float-right pt-20" style={{ lineHeight: "4rem" }}>
                Fast, experienced, and reliable.
              </div>
              <div className="w-3/4 pl-100 text-lg font-medium text-left float-right pt-2" style={{ lineHeight: "1.9rem" }}>
                <div>
                  From start to finish, we work closely with you to understand your vision, goals and target audience, and bring your brand to life online.
                </div>
                <div className="pt-5">
                  Our comprehensive web development services include custom design, theme customization, app integration, and ongoing support and maintenance to ensure the longevity of your online presence.
                </div>
                <div className="pt-20 text-md">
                  Interested in a quote or learning more about our services? <a href="mailto:info@shopifypartners.dev" className="underline">Contact us here</a>
                </div>
              </div>



              
            </div>
            <div className="w-3/5">
              <img className="h-1/2 m-auto" src="/shopify.png" />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
